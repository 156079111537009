import { useSelector } from "react-redux";
import Table from "../table";
import "../style.scss";

export default function OrganicTraffic({ tableTitle = "" }) {
  const { Semrush } = useSelector((state) => ({
    Semrush: state.dataReducer.Semrush ?? [],
  }));

  return (
    <main>
      <h3>{tableTitle}</h3>
      <Table data={Semrush} />
    </main>
  );
}
