import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import SearchBox from "../SearchBox";
import logo from "../../assets/images/logo.png";
import "./style.scss";

export default function Search({
  status,
  emitSearchEvent,
  searchKeyword,
  setSearchKeyword,
  handleKeyPress,
  inputType,
  setInputType,
}) {
  useEffect(() => {
    if (status === "pending") {
      toast("We're working on scrapping the required data.");
    }
    if (status === "error") {
      toast("Something went wrong, either use another domain or try again.", {
        type: "error",
      });
    }
  }, [status]);

  return (
    <div className="initial-view">
      <img src={logo} alt="logo" />
      {/* Radio buttons to toggle between SearchBox and Textarea */}
      <div className="input-toggle" style={{ marginBottom: "15px" }}>
        <label style={{ marginRight: "15px" }}>
          <input
            type="radio"
            value="searchbox"
            checked={inputType === "keyword"}
            onChange={() => setInputType("keyword")}
          />
          Keyword
        </label>
        <label>
          <input
            type="radio"
            value="textarea"
            checked={inputType === "urls_list"}
            onChange={() => setInputType("urls_list")}
          />
          URLs
        </label>
      </div>
      <SearchBox
        keyword={searchKeyword}
        setKeyword={setSearchKeyword}
        onKeyPress={handleKeyPress}
        showSearchIcon={false}
        inputType={inputType}
        setInputType={setInputType}
        type={inputType}
      />

      <button onClick={emitSearchEvent} disabled={status === "searching"}>
        {status === "searching" ? "Searching..." : "Pesquisar"}
      </button>
    </div>
  );
}
