import { useSelector } from "react-redux";
import Table from "../table";
import "../style.scss";

export default function FacebookAds({ tableTitle }) {
  const { FacebookAds } = useSelector((state) => ({
    FacebookAds: state.dataReducer.FacebookAds ?? [],
  }));

  return (
    <main>
      <h3>{tableTitle}</h3>
      <Table data={FacebookAds} />
    </main>
  );
}
