import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";
import { toast, ToastContainer } from "react-toastify";
import { CircularProgress } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";

import {
  setKeyword,
  setTableData as setTableDataInRedux,
  clearTableData,
} from "./utils/Redux/actions";

import Sidebar from "./components/Sidebar";
import FacebookAds from "./components/FacebookAds";
import GoogleAds from "./components/GoogleAds";
import OrganicTraffic from "./components/OrganicTraffic";
import GoogleMaps from "./components/GoogleMaps";
import Instagram from "./components/Instagram";
import YouTube from "./components/Youtube";
import SimilarWeb from "./components/SimilarWeb";
import InstagramInfluencers from "./components/InstagramInfluencers";
import InstagramInfluencersAnalytics from "./components/InstagramInfluencersAnalytics";
import BrandPopularity from "./components/BrandPopularity";
import Search from "./components/Search";
import NotAvailable from "./components/NotAvailable";
import Twitter from "./components/Twitter";

import "./style.scss";

const token = "761daf42b06afa0afa6a700a5e173f89d272255f";
const tables = {
  home: "home",
  "organic-traffic": "Semrush",
  facebook_ads: "FacebookAds",
  "google-ads": "GoogleAds",
  dataforseo: "Dataforseo",
  google_maps: "GoogleMaps",
  // instagram_socialblade: "instagram_socialblade",
  // youtube_socialblade: "youtube_socialblade",
  // instagram_influencers: "instagram_influencers",
  // dataforseo: "dataforseo",
  // instagram_influencers_counter: "instagram_influencers_counter",
  // twitter_socialblade: "twitter_socialblade",
};

export default function App() {
  const dispatch = useDispatch();

  const cachedTablesData = useSelector((state) => state.dataReducer);

  const { keyword } = useSelector((state) => ({
    keyword: state.dataReducer?.keyword,
  }));

  const [downloadURL, setDownloadURL] = useState("");
  const [data, setData] = useState(null);
  const [activeTab, setActiveTab] = useState("organic-traffic");
  const [status, setStatus] = useState(null);
  let [searchKeyword, setSearchKeyword] = useState("");
  const [inputType, setInputType] = useState("keyword");
  const [socket, setSocket] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isConnectedOnce, setIsConnectedOnce] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const setupSocketListeners = (newSocket) => {
    newSocket.on("connect", () => {
      setStatus("connected");
      setIsConnectedOnce(true);
    });

    newSocket.on("connect_error", (error) => {
      console.error("Connection Error:", error.message);
      setStatus("error");
      toast.error(`Connection Error: ${error.message}`);
    });

    newSocket.on("disconnect", () => {
      setStatus("disconnected");
    });

    newSocket.on("status", (data) => {
      setStatus(data.status);

      // Check if status is 'finished' and use searchKeyword for the key
      if (data.status === "finished") {
        setDownloadURL(
          "https://test.espiaobrasileiro.com.br" + (data.url || "")
        );
        setActiveTab("organic-traffic");
        dispatch(setKeyword(searchKeyword)); // Use searchKeyword directly
        requestTableData(newSocket, tables[activeTab]); // Use searchKeyword as the key
        setData(data);
      } else if (data.status === "error") {
        console.error("Error:", data.error);
      }
    });

    newSocket.on("get_table_data", (data) => {
      console.log("table data === ", data);
      if (data.data) {
        dispatch(setTableDataInRedux({ key: data.table, value: data.data }));
      } else {
        console.error("Table not available:", data.table);
      }
    });

    newSocket.on("timeout", () => {
      setStatus("timeout");
      setActiveTab("organic-traffic");
      setSearchKeyword("");
      newSocket.disconnect();
      setSocket(null);
    });
  };

  const requestTableData = (socket, table) => {
    if (!cachedTablesData[table]?.length) {
      socket.emit("get_table_data", { table }); // key is now searchKeyword
    }
  };

  useEffect(() => {
    if (status === "searching" && socket) {
      const id = setInterval(() => {
        socket.emit("check_status", { data: "status" });
      }, 5000);
      setIntervalId(id);

      return () => {
        clearInterval(id);
      };
    }
  }, [status, socket]);

  useEffect(() => {
    if (status === "pending" && socket) {
      const id = setInterval(() => {
        socket.emit("check_status");
      }, 10000);
      setIntervalId(id);

      return () => {
        clearInterval(id);
      };
    }
  }, [status, socket]);

  useEffect(() => {
    if (socket) {
      tables[activeTab] !== "home" &&
        requestTableData(socket, tables[activeTab]);
    }
  }, [activeTab, socket, keyword]);

  const emitSearchEvent = () => {
    console.log("inputType === emit", inputType);

    if (inputType === "urls_list") {
      searchKeyword = searchKeyword.trim().split(/\s+/);
    }
    console.log("searchKeyword", searchKeyword);

    if (searchKeyword) {
      dispatch(clearTableData());
      setStatus("searching");

      if (!socket) {
        const newSocket = io(
          "wss://test.espiaobrasileiro.com.br/ws/v1/grand/",
          {
            auth: { token },
            transports: ["websocket", "polling"],
            reconnectionAttempts: 10,
          }
        );
        setSocket(newSocket);
        setupSocketListeners(newSocket);
        // newSocket.emit("search", { keyword: searchKeyword });
        newSocket.emit("search", {
          search_type: "new",
          input_type: inputType ? inputType : "keyword",
          input: searchKeyword,
        });
      } else {
        // socket.emit("search", { keyword: searchKeyword });
        socket.emit("search", {
          search_type: "new",
          input_type: inputType ? inputType : "keyword",
          input: searchKeyword,
        });
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      emitSearchEvent();
    }
  };

  const renderComponent = () => {
    switch (activeTab) {
      case "home":
        return (
          <Search
            status={status}
            keyword={keyword}
            emitSearchEvent={emitSearchEvent}
            searchKeyword={searchKeyword}
            handleKeyPress={handleKeyPress}
            setSearchKeyword={setSearchKeyword}
            inputType={inputType}
            setInputType={setInputType}
          />
        );
      case "organic-traffic":
        return (
          <OrganicTraffic tableTitle={"Tráfego Orgânico pelo Google/SEO"} />
        );
      case "facebook_ads":
        return (
          <FacebookAds tableTitle={"Anúncios Pagos Facebook/Instagram Ads"} />
        );
      case "google-ads":
        return <GoogleAds tableTitle={"Anúncios Pagos Google Ads"} />;
      case "google_maps":
        return <GoogleMaps tableTitle={"Google Maps"} />;
      // case "similarweb":
      //   return <SimilarWeb tableTitle={"SimilarWeb"} />;
      // case "instagram_influencers":
      //   return (
      //     <InstagramInfluencers tableTitle={"Influenciadores do Instagram"} />
      //   );
      case "dataforseo":
        return <BrandPopularity tableTitle={"Popularidade da Marca"} />;
      // case "instagram_influencers_counter":
      //   return <InstagramInfluencersAnalytics />;
      default:
        return (
          <Search
            status={status}
            keyword={keyword}
            emitSearchEvent={emitSearchEvent}
            searchKeyword={searchKeyword}
            handleKeyPress={handleKeyPress}
            setSearchKeyword={setSearchKeyword}
            inputType={inputType}
            setInputType={setInputType}
          />
        );
    }
  };

  if (screenWidth < 1024) {
    return <NotAvailable />;
  }

  if (status === "pending") {
    return (
      <div className="loading-spinner">
        <CircularProgress />
        <h3>A pesquisa está sendo realizada, aguarde 5 minutos..</h3>
      </div>
    );
  }

  return (
    <div className="app">
      <ToastContainer />
      {!data ? (
        <Search
          status={status}
          emitSearchEvent={emitSearchEvent}
          searchKeyword={searchKeyword}
          handleKeyPress={handleKeyPress}
          setSearchKeyword={setSearchKeyword}
          inputType={inputType}
          setInputType={setInputType}
        />
      ) : (
        <>
          <Sidebar
            downloadURL={downloadURL}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            searchKeyword={searchKeyword}
            setKeyword={setSearchKeyword}
            emitSearchEvent={emitSearchEvent}
          />
          <div className="main-content">{renderComponent()}</div>
        </>
      )}
    </div>
  );
}
