import { useSelector } from "react-redux";
import Table from "../table";
import "../style.scss";

export default function GoogleMaps({ data = [], tableTitle = "" }) {
  const { GoogleMaps } = useSelector((state) => ({
    GoogleMaps: state.dataReducer.GoogleMaps ?? [],
  }));

  return (
    <main>
      <h3>{tableTitle}</h3>
      <Table data={GoogleMaps} />
    </main>
  );
}
