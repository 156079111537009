import { useEffect, useState } from "react";
import { notFound } from "../../utils/SVGs";
import externalLink from "../../assets/images/external-link.svg";
import "./style.scss";

const isValidUrl = (value) => {
  try {
    new URL(value);
    return true;
  } catch {
    return false;
  }
};

export default function Table({ data = [] }) {
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    if (!keys.length && data.length) {
      // Filter out the "Logo" key
      const filteredKeys = Object.keys(data[0]).filter((key) => key !== "Logo");
      setKeys(filteredKeys);
    }
  }, [data, keys.length]);

  if (!data.length) {
    return (
      <div className="not-found">
        {notFound}
        <span>No Data Found.</span>
      </div>
    );
  }

  console.log("data === ", data);
  console.log("keys === ", keys);

  return (
    <div className="table-wrapper">
      <div className="table-container">
        <div className="table-head table-row">
          {keys.map((key) => (
            <span
              key={key}
              className={`table-cell ${
                key === "Empresa" ? "empresa-cell" : ""
              }`}
            >
              {key}
            </span>
          ))}
        </div>
        {data.map((row, rowIndex) => (
          <div className="table-row" key={rowIndex}>
            {keys.map((key) => (
              <span
                key={key}
                className={`table-cell ${
                  key === "Empresa" ? "empresa-cell" : ""
                }`}
              >
                {key !== "Empresa" && isValidUrl(row[key]) ? null : key ===
                    "Empresa" && isValidUrl(row[key]) ? (
                  <a href={row[key]} target="_blank" rel="noopener noreferrer">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        gap: "10px",
                      }}
                    >
                      <img
                        style={{
                          width: "50px",
                          height: "auto",
                          backgroundColor: "#fff",
                          border: "1px solid #EFEFEF",
                          borderRadius: "5px",
                          padding: "10px 15px",
                        }} // Fixed width with auto height
                        src={row?.Logo}
                        alt="external link"
                      />
                      <span>{row[key]}</span>
                    </div>
                  </a>
                ) : (
                  row[key]
                )}
              </span>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
