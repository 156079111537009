import { useDispatch } from "react-redux";
import { setLoading } from "../../utils/Redux/actions";
import { SearchIcon } from "../../utils/SVGs";
import "./style.scss";

export default function SearchBox({
  keyword,
  setKeyword,
  onKeyPress,
  emitSearchEvent,
  showSearchIcon = true,
  type,
}) {
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    setKeyword(e.target.value);
  };

  const handleSearch = () => {
    dispatch(setLoading());
    emitSearchEvent();
  };
  return (
    <div className="search-box">
      {type === "keyword" ? (
        <input
          type="text"
          value={keyword}
          onChange={handleInputChange}
          onKeyPress={onKeyPress}
          placeholder="pesquisar palavra"
          name="search"
          autoComplete="on"
        />
      ) : (
        <textarea
          style={{ border: "none" }}
          className="search-box"
          value={keyword}
          onChange={handleInputChange}
          onKeyPress={onKeyPress}
          placeholder="pesquisar palavra"
          name="search"
        />
      )}

      {showSearchIcon && <div onClick={handleSearch}>{SearchIcon}</div>}
    </div>
  );
}
