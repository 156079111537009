import { useSelector } from "react-redux";
import Table from "../table";
import "../style.scss";

export default function GoogleAds({ tableTitle = "" }) {
  const { GoogleAds } = useSelector((state) => ({
    GoogleAds: state.dataReducer.GoogleAds ?? [],
  }));

  return (
    <main>
      <h3>{tableTitle}</h3>
      <Table data={GoogleAds} />
    </main>
  );
}
