import { useSelector } from "react-redux";
import Table from "../table";
import "../style.scss";

export default function BrandPopularity({ tableTitle = "" }) {
  const { Dataforseo } = useSelector((state) => ({
    Dataforseo: state.dataReducer.Dataforseo ?? [],
  }));

  return (
    <main>
      <h3>{tableTitle}</h3>
      <Table data={Dataforseo} />
    </main>
  );
}
